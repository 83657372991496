@font-face {
  font-family: 'Century';
  src: url(./fonts/century.woff) format('woff');
}

a,
a:active {
  color: inherit;
}

input,
select,
textarea {
  outline: none;
  -webkit-appearance: none;
  border-radius: 0;
}

input[type='checkbox'] {
  -webkit-appearance: checkbox;
}

input[type='radio'] {
  -webkit-appearance: radio;
}

input::-webkit-input-placeholder {
  line-height: normal;
}

.sidebar {
  transform: translateX(-100%);
}

.white-placeholder::-webkit-input-placeholder {
  color: var(--white);
  opacity: 0.7;
}

::-webkit-input-placeholder {
  color: var(--gray-600);
}

body {
  overflow: hidden;
  line-height: 1.618rem;
  color: var(--black-80);
}

.sidebar,
.content {
  transition: transform 0.3s;
}

.chrome--pushed .sidebar {
  transform: translateX(0);
}

.chrome--pushed .content {
  transform: translateX(256px);
}

.sidebar-link--active {
  box-shadow: 0px 1px 3px 0px var(--black-05);
}

.no-pointer-events {
  pointer-events: none;
}

@keyframes loadingSpinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-animation {
  animation: loadingSpinner 1.5s infinite cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: translateZ(0);
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-top: 3px solid white;
}

.loading-animation--dark {
  animation: loadingSpinner 1.5s infinite cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: translateZ(0);
  border: 3px solid rgba(142, 142, 142, 0.2);
  border-top: 3px solid #3f3f3f;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
  }
}

.bounce {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: bounce;
  animation-name: bounce;
}

.fake-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.fake-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  padding-right: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.pb9 {
  padding-bottom: calc(8 * 9px);
}

.pb12 {
  padding-bottom: calc(6 * 16px);
}

.absolute-center {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
}

.touch-momentum-scroll {
  -webkit-overflow-scrolling: touch;
}

.bg-customer-blue {
  background-color: #292f3d;
}

@media print {
  @page {
    size: auto; /* auto is the initial value */
    margin: 0; /* this affects the margin in the printer settings */
  }

  *:not(.printable) {
    visibility: hidden;
  }

  body {
    height: 1.65in;
    width: 1.9in;
    position: relative;
    padding-top: 0.1in;
    padding-left: 0.1in;
    margin-right: 0;
    float: left;
  }

  .content {
    display: none;
  }

  .printable,
  .printable *,
  .printable > * {
    display: block !important;
    visibility: visible !important;
  }

  .printable {
    height: 1.65in !important;
    width: 1.9in !important;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--white);
  }

  .printable canvas {
    transform: rotate(-90deg);
    height: 31% !important;
    position: absolute !important;
    right: -45px !important;
    top: 49px !important;
  }

  .printable hr {
    margin: 0.5rem auto !important;
  }

  .printable div {
    font-size: 10px;
    width: 55% !important;
    line-height: 1rem;
    padding-top: 0 !important;
  }

  .printable .f4 {
    font-size: 12px;
  }

  .printable span {
    margin-bottom: 0 !important;
  }
}

.hardware-accelerated {
  transform: translate3d(0, 0, 0);
}

.icon--bullet-list:before,
.icon--clock:before {
  font-weight: bold;
}

.disabled-action {
  pointer-events: none;
}

.form__input--disabled {
  background-color: var(--gray-900);
}

.disabled {
  color: var(--gray-900);
}

.select-placeholder {
  color: var(--gray-600);
}

.flex-date-picker {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--gray-800);
}

.disable-double-tap-zoom {
  touch-action: manipulation;
}

/* overrides for react-dates */

.DateRangePicker .DateInput_input {
  font-family: inherit;
  font-weight: 400;
}

.DateRangePicker .DateInput_input__focused {
  border-bottom-color: var(--gray-700);
}

.DateRangePicker .CalendarDay__selected,
.DateRangePicker .CalendarDay__selected:hover,
.DateRangePicker .CalendarDay__selected_span,
.DateRangePicker .CalendarDay__selected_span:hover,
.DateRangePicker .CalendarDay__hovered_span,
.DateRangePicker .CalendarDay__hovered_span:hover {
  background: var(--gray-800);
  border-color: var(--gray-800);
}

.w-22 {
  width: 22.65%;
}

.algolia-search::-webkit-search-cancel-button {
  display: none;
}

.highlight-on-click:active {
  background-color: white;
  color: black;
}

.scrollable-table__row-enter {
  opacity: 0;
  transition: all 420ms ease-out;
}

.scrollable-table__row-enter-active {
  opacity: 1;
  max-height: 1000px;
}
.scrollable-table__row-exit {
  opacity: 1;
  max-height: 1000px;
  transition: all 420ms ease-out;
}

.scrollable-table__row-exit-active {
  opacity: 0;
  max-height: 0;
}

@keyframes connectingColorPulse {
  0% {
    fill: var(--gray-400);
  }

  50% {
    fill: var(--gray-700);
  }

  100% {
    fill: var(--gray-400);
  }
}

.connecting-color-pulse--connecting path {
  animation: connectingColorPulse infinite 2s;
}

.connecting-color-pulse--connected path {
  fill: var(--green-400);
  transition: fill 2s ease-in;
}

.connecting-color-pulse--failed path {
  fill: var(--red-400);
  transition: fill 2s ease-in;
}

.connecting-color-pulse--hidden {
  display: none;
}

.striped--light-gray > :nth-child(odd) {
  background-color: var(--gray-900);
}

::-webkit-calendar-picker-indicator {
  margin-left: 0;
}

/* Safari Only */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    input[type='date'].fake-placeholder:not(:focus):before {
      color: var(--gray-600);
      content: attr(placeholder);
    }
  }
}

@keyframes flash {
  0% {
    visibility: visible;
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes hide {
  0% {
    visibility: hidden;
  }

  100% {
    visibility: hidden;
  }
}

.flash {
  visibility: hidden;
  animation: flash 600ms 1, hide 0ms 600ms forwards;
  animation-iteration-count: 1;
}

.PhoneInputInput {
  padding: 10px;
  height: var(--height-6);
  border: 1px solid var(--gray-600);
}

.customer-details-phone-input.PhoneInput {
  background-color: var(--gray-2);
}

.customer-details-phone-input .PhoneInputInput {
  width: 100% !important;
  height: var(--height-6) !important;
  padding: 10px !important;
  background-color: var(--gray-2) !important;
  border: 1px solid white !important;
  color: white !important;
}

.customer-details-phone-input .PhoneInputCountrySelect {
  color: white;
  background-color: var(--gray-2);
}

.customer-details-phone-input .PhoneInputInput::placeholder {
  color: white;
  opacity: 0.7;
}
